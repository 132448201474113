<template>
	<v-container fluid class="py-0 px-0">
		<v-row no-gutters>
			<v-col md="4" class="d-none d-md-block banner-join-us"></v-col>

			<v-col cols="12" md="8" offset-md="4" class="pa-4">
				<h5 class="text-h5 my-2">Fale um pouco sobre você</h5>
				<p class="subtitle-1 mt-n2 mb-6">Essas informações serão usadas para criar sua página e app</p>
				
				<v-form lazy-validation v-model="isOwnerValid" ref="frmOwner">
					<v-row class="fill-height">
						<v-col cols="12" sm="10" md="9">
							<v-text-field clearable dense filled counter v-model="name" :rules="nameRules" tabindex="1" color="orange darken-2" ref="txt6" prepend-inner-icon="store" label="Qual é o Nome da sua Empresa?" maxlength="30" />
						</v-col>

						<v-col cols="12" sm="10" md="9">
							<v-text-field clearable dense filled counter v-model="fullname" :rules="nameRules" prepend-inner-icon="person" tabindex="2" color="orange darken-2" label="Qual é o Seu Nome?" maxlength="25" />
						</v-col>

						<v-col cols="12" sm="10" md="9">
							<v-text-field clearable dense filled counter v-model="phone" :rules="phoneRules" v-mask="'(##) 9####-####'" tabindex="3" prepend-inner-icon="whatsapp" label="Qual é o Seu Whatsapp?" color="orange darken-2" placeholder="Digite apenas os números" inputmode="numeric" maxlength="15" />
						</v-col>

						<v-col cols="12" sm="10" md="9">
							<v-text-field clearable dense filled counter v-model="email" :rules="emailRules" prepend-inner-icon="email" label="Qual é o Seu E-mail?" maxlength="100" :disabled="checkingEmail" tabindex="4" color="orange darken-2" type="email" :error-messages="emailErrors" @blur="checkEmail">
								<template v-slot:append>
									<v-progress-circular indeterminate v-if="checkingEmail" size="24" />
								</template>
							</v-text-field>
						</v-col>

						<v-col cols="12" sm="10" md="9">
							<v-text-field clearable dense filled counter v-model="password" :rules="passwordRules" prepend-inner-icon="security" color="orange darken-2" label="Crie uma Senha" tabindex="5" placeholder="letras e números" maxlength="20" :append-icon="showpass ? 'visibility' : 'visibility_off'" :type="showpass ? 'text' : 'password'" @click:append="showpass = !showpass" />
						</v-col>

						<v-col cols="12">
							<v-checkbox v-model="acceptedTerms" color="orange darken-2" tabindex="6" class="mt-0 ml-2">
								<template v-slot:label>
									<span>
										Eu declaro que li e aceito os
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<a @click.stop v-on="on" target="_blank" href="https://appagendamento.com/#/termos-de-uso" color="orange darken-2">termos de uso.</a>
											</template>
											Clique para ler
										</v-tooltip>
									</span>
								</template>
							</v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mb-3">
						<v-col cols="12" sm="10" md="9" align="end">
							<v-btn depressed :disabled="!isOwnerValid || !acceptedTerms || checkingEmail" @click="next()" :loading="loading" color="primary" tabindex="7">avançar</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>

		<v-dialog v-model="showdialog" max-width="290">
			<v-card>
				<v-card-title class="headline">{{dialogtitle}}</v-card-title>
				<v-card-text>{{dialogtext}}</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="indigo darken-1" text @click="showdialog = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="stoast">{{mtoast}}</v-snackbar>
	</v-container>
</template>

<script>
import sha1 from "sha1"
import { getFunctions, httpsCallable } from "firebase/functions"
const functions = getFunctions();

export default {
	name: 'create-account',

	mounted() {
		fbq('track', 'InitiateCheckout')
		this.$emit('handle', 'hide-wpp')

		setTimeout(() => {
			this.$refs.txt6.focus()
		}, 500);
	},

	destroyed() { 
		this.$emit('handle', 'show-wpp')
	},
  
	methods: {

		next() {
			if(this.$refs.frmOwner.validate()) {
				this.$emit('handle', 'captcha-check')
				fbq('track', 'Subscribe')
				this.loading = true
			}
		},

		async onTokenCreated(token) {
			const res = await httpsCallable(functions, 'register')({
				plan: 'basic', password: sha1(this.password), phone: this.phone.replace(/[\(\)\s\-]/g, ''), token,
				appName: this.name, fullname: this.fullname, email: this.email,
			})
			.then(res => res.data).catch(err => {
				console.error('onTokenCreated', err)
				return {  success: false, message: 'Tente novamente mais tarde.' }
			})

			if(!res.success) {
				grecaptcha.reset()
				this.loading = false
				this.showMessageBox('Oops', res.message)
			}
			else setTimeout(() => {
				location.assign(res.payment)
			}, 200);
		},

		checkEmail() {
			if(this.emailRules.every(rule => rule(this.email) === true)) {
				this.checkingEmail = true
				
				httpsCallable(functions, 'isUnique')({ value: this.email }).then(res => {
					const { success, message } = res.data

					this.checkingEmail = false
					this.emailErrors = success ? [] : [message]
				})
				.catch(err => {
					console.log('check email error', {
						param: this.email,
						error: err
					})

					this.checkingEmail = false
					this.emailErrors = ['Não foi possível verificar o email']
				})
			}
		},

		showMessageBox(title, text) {
			this.showdialog = true
			this.dialogtitle = title
			this.dialogtext = text
		},
	},

	data() {
		return {
			stoast: false, mtoast: '',
			showdialog: false, dialogtitle: '', dialogtext: '',
		 	showpass: false, loading: false, checkingEmail: false, 
			emailErrors: [], acceptedTerms: false, isOwnerValid: true,
			fullname: '', email: '', password: '', name: '', phone: '',

			nameRules: [
				value => !!value || 'Preencha esse campo.',
				value => (value && value.length >= 3) || 'Esse nome é muito pequeno.',
			],

			emailRules: [
				value => !!value || 'Preencha esse campo.',
				value => /.+@.+\..+/.test(value) || 'Informe um email válido.',
				value => (value && value.length > 10) || 'O email é muito pequeno.',
			],

			passwordRules: [
				value => !!value || 'Preencha esse campo.',
				value => (value && value.length > 5) || 'A senha deve ter no mínimo 6 caracteres.',
				value => /[a-z]+/.test(value) || 'A senha deve ter pelo menos 1 letra.',
				value => /[0-9]+/.test(value) || 'A senha deve ter pelo menos 1 número.',
			], 

			phoneRules: [
				value => !!value || 'Preencha esse campo.',
				value => (value && value.length == 15) || 'O telefone é muito pequeno.',
				value => /^\(\d{2}\)\s\d{5}\-\d{4}$/.test(value) || 'O telefone está inválido.',
			],
		}
	}
}
</script>

<style lang="scss">
.banner-join-us {
	background-image: url(../assets/slides/4.jpg);
	background-position: center;
	background-size: cover;
	height: calc(100vh - 64px);
	position: fixed;
}
</style>